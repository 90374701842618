import React from "react";
import styled from "styled-components";

const SingleHotelPage = () => {
	return (
		<SingleHotelPageWrapper>
			<div>Hello From SingleHotelPage</div>
		</SingleHotelPageWrapper>
	);
};

export default SingleHotelPage;

const SingleHotelPageWrapper = styled.div`
	min-height: 800px;
`;
